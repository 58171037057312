import Vid from '../assets/testVid.mp4';
import imag from '../assets/abs.jpg';


function Header() {
  return (
    <header>
      {/* <video src={Vid} loop autoPlay muted></video> */}
      <h2 style={{color:'white'}}>Inspiring Generations, Creating Opportunities, and Building a Sustainable Future Together</h2>
      <div className="headerbg"></div>
    </header>

    
  );
}
export default Header;
